import {
  Bold,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hyperlink,
  Italics,
  ListItem,
  OrderedList,
  Paragraph,
  Underline,
  UnorderedList,
} from '@snapchat/snap-design-system-marketing';
import parse, {
  type Element as ElementType,
  type HTMLReactParserOptions,
  domToReact,
  Element,
} from 'html-react-parser';
import type { ReactNode } from 'react';

/** Options for HTML parsing, including SDS-M primtiive components. */

const options: HTMLReactParserOptions = {
  replace(domNode, index) {
    if (!(domNode instanceof Element)) return null;

    let PrimitiveComponent;

    switch (domNode.name) {
      // Validate if domNode is a heading
      case 'h1':
        PrimitiveComponent = H1;
        break;
      case 'h2':
        PrimitiveComponent = H2;
        break;
      case 'h3':
        PrimitiveComponent = H3;
        break;
      case 'h4':
        PrimitiveComponent = H4;
        break;
      case 'h5':
        PrimitiveComponent = H5;
        break;
      case 'h6':
        PrimitiveComponent = H6;
        break;
      // Validate if domNode is a hyperlink
      case 'a':
        PrimitiveComponent = Hyperlink;
        break;
      // Validate if domNode is a marker
      case 'b':
        PrimitiveComponent = Bold;
        break;
      case 'i':
        PrimitiveComponent = Italics;
        break;
      case 'u':
        PrimitiveComponent = Underline;
        break;
      // Validate if domNode is a paragraph
      case 'p':
        PrimitiveComponent = Paragraph;
        break;
      // Validate if domNode is a list element
      case 'li':
        PrimitiveComponent = ListItem;
        break;
      case 'ol':
        PrimitiveComponent = OrderedList;
        break;
      case 'ul':
        PrimitiveComponent = UnorderedList;
        break;
      default:
        return null;
    }

    return (
      <PrimitiveComponent
        key={index}
        // link prop for Hyperlink elements
        link={domNode.attribs.href}
      >
        {domToReact(domNode.children as ElementType[], options)}
      </PrimitiveComponent>
    );
  },
};

/**
 * Converts a raw HTML job description string into React SDS-M primitive components.
 *
 * This function leverages the 'html-react-parser' library to parse the HTML content and then
 * recursively transforms it into React components based on HTML element types.
 *
 * @param rawDescription - The raw HTML string representing the job description.
 * @returns A ReactNode representing the structured React elements from the parsed HTML.
 */
export const JobDescriptionToSdsm = (rawDescription: string): ReactNode => {
  return parse(rawDescription, options);
};
