import { gql } from '@apollo/client';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import { ctaFragment } from '../../../../components/CallToAction/query';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const jobDescriptionBlockQuery = gql`
  query JobDescriptionBlock($id: String!, $preview: Boolean!, $locale: String!) {
    jobDescriptionBlock(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      backgroundColor
      callToAction {
        ... on CallToAction {
          ...CallToActionAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${ctaFragment}
`;

export type JobDescriptionBlockProps = ContentfulTypedSysProps<'JobDescriptionBlock'> & {
  backgroundColor: BackgroundColor;
  callToAction: CallToActionDataProps;
};
export interface JobDescriptionBlockDataHandlerProps {
  jobDescriptionBlock: JobDescriptionBlockProps;
}
