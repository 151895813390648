export enum JobFilterField {
  LOCATION = 'location',
  ROLE = 'role',
  TEAM = 'team',
  TYPE = 'type',
}

export const filterFieldMap = {
  [JobFilterField.LOCATION]: 'offices.name',
  [JobFilterField.ROLE]: 'role',
  [JobFilterField.TEAM]: 'departments',
  [JobFilterField.TYPE]: 'employment_type',
};

/** Delimiter the API will use to signal the search hit within a fragment. */
export const searchTermHitDelimiter = '~~';
