import { gql } from '@apollo/client';

import type { ImageDataProps } from '../../../../components/Image';
import { fragments as imageFragments } from '../../../../components/Image';
import type { VideoDataProps } from '../../../../components/Video';
import { fragments as videoFragments } from '../../../../components/Video';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const jobHeroQuery = gql`
  query JobHeroQuery($id: String!, $preview: Boolean!, $locale: String!) {
    jobHero(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      backgroundMedia {
        __typename
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${imageFragments.all}
  ${videoFragments.all}
`;

export type JobHeroProps = ContentfulTypedSysProps<'BlockHero'> & {
  backgroundMedia: ImageDataProps | VideoDataProps;
};
export interface JobHeroDataHandlerProps {
  jobHero: JobHeroProps;
}
