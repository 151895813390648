import { gql } from '@apollo/client';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import { ctaFragment } from '../../../../components/CallToAction/query';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const jobApplyNowBlockQuery = gql`
  query JobApplyNowBlock($id: String!, $preview: Boolean!, $locale: String!) {
    jobApplyNowBlock(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      title
      backgroundColor
      callToAction {
        ... on CallToAction {
          ...CallToActionAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${ctaFragment}
`;

export type JobApplyNowBlockProps = ContentfulTypedSysProps<'JobApplyNowBlockProps'> & {
  title: string;
  backgroundColor: BackgroundColor;
  callToAction: CallToActionDataProps;
};

export interface JobApplyNowBlockDataHandlerProps {
  jobApplyNowBlock: JobApplyNowBlockProps;
}
