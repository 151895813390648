import { cx } from '@emotion/css';
import {
  BlockBoundary,
  FilterDropdownMenu,
  MessageContext,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useContext } from 'react';

import { SearchBar } from '../SearchBar/SearchBar';
import { JobFilterField } from './constants';
import { JobsTable } from './JobsTable';
import {
  boundaryCss,
  filterContainerCss,
  filtersContainerCss,
  searchHeaderCss,
  searchSectionCss,
} from './styles';
import type { DropdownMenu, JobFilters, JobPostingsProps } from './types';

const DropdownMenus: FC<DropdownMenu> = ({
  filterNames,
  filterValues,
  filters,
  handleFilterChange,
}) => {
  const { formatMessage } = useContext(MessageContext);
  const filterTitleMap: Record<JobFilters, string> = {
    role: formatMessage({ id: 'roleFilterTitle', defaultMessage: 'All roles' }),
    location: formatMessage({ id: 'locationFilterTitle', defaultMessage: 'All locations' }),
    team: formatMessage({ id: 'teamFilterTitle', defaultMessage: 'All teams' }),
    type: formatMessage({ id: 'typeFilterTitle', defaultMessage: 'All types' }),
  };

  return (
    <div className={filtersContainerCss}>
      {filterNames.map((filterName: JobFilters) => {
        const title = filterTitleMap[filterName];

        return (
          <FilterDropdownMenu
            key={filterName}
            items={
              filterValues?.[filterName]?.map((office: string) => ({
                id: office,
                title: office,
              })) ?? []
            }
            title={title}
            id={filterName}
            allItemTitle={title}
            onChange={value => handleFilterChange(filterName, value)}
            value={filters[filterName]}
            isStandalone={false}
          />
        );
      })}
    </div>
  );
};

/**
 * Returns the job listings page.
 *
 * @param filters - Array of applied filters
 * @param filterValues - Object containing all filter values grouped by keys
 * @param jobs - Jobs to display
 */
export const JobPostings: FC<JobPostingsProps> = ({
  filterValues,
  jobs,
  filters,
  isLoading,
  setQuery,
}) => {
  const handleFilterChange = useCallback(
    (filterId: string, filterValue?: string) => {
      const params = new URLSearchParams(window.location.search);

      if (!filterValue) {
        params.delete(filterId);
      } else {
        params.set(filterId, filterValue);
      }

      window.history.pushState('', '', `?${params.toString()}`);
      setQuery(params);
    },
    [setQuery]
  );

  const filterNames: JobFilters[] = [
    JobFilterField.LOCATION,
    JobFilterField.TEAM,
    JobFilterField.ROLE,
    JobFilterField.TYPE,
  ];

  return (
    <article data-test-id="mwp-job-postings">
      <section data-test-id="jobs-filter-section" className={searchSectionCss}>
        <BlockBoundary className={boundaryCss}>
          <h4 className={cx(searchHeaderCss)}>Jobs at Snap Inc.</h4>
          <div className={cx(filterContainerCss)}>
            <DropdownMenus
              filterValues={filterValues}
              filters={filters}
              handleFilterChange={handleFilterChange}
              filterNames={filterNames}
            />
            <SearchBar setQuery={setQuery} />
          </div>
        </BlockBoundary>
      </section>

      <BlockBoundary className={boundaryCss}>
        <JobsTable isLoading={isLoading} jobs={jobs} />
      </BlockBoundary>
    </article>
  );
};
