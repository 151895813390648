import type { FC } from 'react';

import { IntoPageBottomStickyPortal } from '../../../../components/Page/PageBottomStickyPortal';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { PersistentCallToAction } from '../../../for-business/components/PersistentCallToAction';
import { useJobPosting } from '../JobPosting/useJobPosting';
import {
  type JobMobileCallToActionQueryData,
  jobMobileCallToActionQuery,
} from './JobMobileCallToAction.query';

export const JobMobileCallToAction: FC<{ id: string }> = props => {
  const { data: blockData } = useContentfulQuery<
    JobMobileCallToActionQueryData,
    ContentfulIdVariable
  >(jobMobileCallToActionQuery, {
    variables: { id: props.id },
  });

  const { data: jobData, isLoading } = useJobPosting();

  // Redirect to 404 page if Job data is not found
  if (!isLoading && !jobData?.id) {
    return <Redirect path={'/404'} />;
  }

  if (isLoading || !blockData || !jobData) return null;

  return (
    <IntoPageBottomStickyPortal>
      <PersistentCallToAction
        body={blockData.jobMobileCallToAction.body}
        buttonColor={blockData.jobMobileCallToAction.buttonColor}
        backgroundColor={blockData.jobMobileCallToAction.backgroundColor}
        analytics={blockData.jobMobileCallToAction.analytics}
        url={jobData.External_Apply_URL}
      />
    </IntoPageBottomStickyPortal>
  );
};
