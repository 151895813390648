import { Icon, MessageContext } from '@snapchat/snap-design-system-marketing';
import throttle from 'lodash-es/throttle';
import { type Dispatch, type FC, useCallback, useContext, useState } from 'react';

import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { inputCss, rowCss, styledIconCss } from './styles';

const queryKey = 'q';

/** Returns Search Bar component */
export const SearchBar: FC<{ setQuery: Dispatch<URLSearchParams> }> = ({ setQuery }) => {
  const { getUrlParams } = useContext(ConsumerContext);
  const { formatMessage } = useContext(MessageContext);
  const currentUrlParams = getUrlParams?.() ?? {};
  const [searchQuery, setSearchQuery] = useState(currentUrlParams.q);

  const handleQueryChange = useCallback(
    (value: string) => {
      const setQueryParams = throttle((value: string) => {
        const params = new URLSearchParams(window.location.search);

        if (!value) {
          params.delete(queryKey);
        } else {
          params.set(queryKey, value);
        }
        window.history.pushState('', '', `?${params.toString()}`);
        setQuery(params);
      }, 250);

      setSearchQuery(value);
      setQueryParams(value);
    },
    [setQuery]
  );

  const placeHolderText = formatMessage({ id: 'jobSearchBarPlacholder', defaultMessage: 'Search' });

  return (
    <article data-test-id="mwp-jobs-search-bar" className={rowCss}>
      <Icon className={styledIconCss} name="search" />
      <input
        className={inputCss}
        onChange={e => handleQueryChange(e.target.value)}
        placeholder={`${placeHolderText}...`}
        value={searchQuery ?? ''}
      />
    </article>
  );
};
