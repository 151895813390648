import { gql } from '@apollo/client';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import { fragments as analyticsFragments } from '../../../../components/Analytics/query';
import type { AnalyticsDataProps } from '../../../../components/Analytics/types';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fragmentAll = gql`
  fragment JobMobileCallToActionAll on JobMobileCallToAction {
    ...ContentfulSysId
    body
    buttonColor
    backgroundColor
    analytics {
      ...AnalyticsAll
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
`;

export const jobMobileCallToActionQuery = gql`
  query JobMobileCallToActionQuery($preview: Boolean!, $locale: String!, $id: String!) {
    jobMobileCallToAction(preview: $preview, locale: $locale, id: $id) {
      ...JobMobileCallToActionAll
    }
  }
  ${fragmentAll}
`;

export interface JobMobileCallToActionDataProps
  extends ContentfulTypedSysProps<'JobMobileCallToAction'> {
  body: string;
  buttonColor: BackgroundColor;
  backgroundColor: BackgroundColor;
  analytics: AnalyticsDataProps;
}

export interface JobMobileCallToActionQueryData {
  jobMobileCallToAction: JobMobileCallToActionDataProps;
}
