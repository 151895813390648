import { Block as BlockSDS, Content as ContentSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { CallToAction } from '../../../../components/CallToAction';
import type { CallToActionProps } from '../../../../components/CallToAction/types';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { JobDescriptionToSdsm } from '../JobDescriptionToSdsm/JobDescriptionToSdsm';
import { JobListingHeader } from '../JobListingHeader/JobListingHeader';
import { useJobPosting } from '../JobPosting/useJobPosting';
import type { JobDescriptionBlockDataHandlerProps } from './JobDescriptionBlock.query';
import { jobDescriptionBlockQuery } from './JobDescriptionBlock.query';

export const JobDescriptionBlock: FC<{ id: string }> = props => {
  const { data: blockData } = useContentfulQuery<
    JobDescriptionBlockDataHandlerProps,
    ContentfulIdVariable
  >(jobDescriptionBlockQuery, {
    variables: { id: props.id },
  });

  const { data: jobData, isLoading } = useJobPosting();

  // Redirect to 404 page if Job data is not found
  if (!isLoading && !jobData?.id) {
    return <Redirect path={'/404'} />;
  }

  if (isLoading || !blockData || !jobData?.id) return null;

  const jobDescriptionContent = JobDescriptionToSdsm(jobData.jobDescription);

  const ctaProps = {
    ...blockData.jobDescriptionBlock.callToAction,
    presentation: {
      ...blockData.jobDescriptionBlock.callToAction.presentation,
      url: jobData.External_Apply_URL,
    },
  };

  return (
    <BlockSDS
      backgroundColor={blockData?.jobDescriptionBlock?.backgroundColor}
      maxColumns={1}
      widthStyle="reduced"
    >
      <ContentSDS
        body={<JobListingHeader jobId={jobData.id} {...jobData} />}
        callsToAction={[
          <CallToAction key={ctaProps.sys.id} {...(ctaProps as CallToActionProps)} />,
        ]}
      />
      <ContentSDS body={jobDescriptionContent} />
    </BlockSDS>
  );
};
