import { Alignment, Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import { Content } from '../../../../components/Content';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { useJobPosting } from '../JobPosting/useJobPosting';
import type { JobApplyNowBlockDataHandlerProps } from './JobApplyNowBlock.query';
import { jobApplyNowBlockQuery } from './JobApplyNowBlock.query';

export const JobApplyNowBlock: FC<{ id: string }> = props => {
  const { data: blockData } = useContentfulQuery<
    JobApplyNowBlockDataHandlerProps,
    ContentfulIdVariable
  >(jobApplyNowBlockQuery, {
    variables: { id: props.id },
  });

  const { data: jobData, isLoading } = useJobPosting();

  // Redirect to 404 page if Job data is not found
  if (!isLoading && !jobData?.id) {
    return <Redirect path={'/404'} />;
  }

  if (isLoading || !blockData || !jobData) return null;

  const callToAction = {
    ...blockData.jobApplyNowBlock.callToAction,
    presentation: {
      ...blockData.jobApplyNowBlock.callToAction.presentation,
      url: jobData.External_Apply_URL,
    },
  };

  return (
    <BlockSDS
      backgroundColor={blockData?.jobApplyNowBlock?.backgroundColor}
      maxColumns={1}
      title={blockData?.jobApplyNowBlock.title}
      titleAlignment={Alignment.Center}
      titleAlignmentMobile={Alignment.Center}
      widthStyle="reduced"
    >
      <Content
        sys={blockData.jobApplyNowBlock.sys}
        title={jobData.title}
        bodyAlignment={Alignment.Center}
        bodyAlignmentMobile={Alignment.Center}
        titleAlignment={Alignment.Center}
        titleAlignmentMobile={Alignment.Center}
        hasLinks={false}
        /**
         * The following cast is necessary due to possible differences on the callToActionCollection
         * prop type. It can be whether CallToActionDataProps or ImageButton.
         */
        callsToActionCollection={{ items: [callToAction as CallToActionDataProps] }}
      />
    </BlockSDS>
  );
};
