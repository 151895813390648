import { useAsyncData } from '@snapchat/async-data-browser';
import { useContext } from 'react';

import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { Config } from '../../../../config';
import { logger } from '../../../../helpers/logging';
import { customFetch } from '../../../../utils/fetch/customFetch';
import type { JobData } from '../JobsPostings/types';

type JobPostingData = {
  data?: JobData;
  isLoading: boolean;
};

/**
 * Used to get Job posting data via Job Id from Careers API:
 * https://careers.snap.com/api/job_details?id=JobId JobId is used as a url param.
 *
 * @returns Job Posting data
 */
export function useJobPosting(): JobPostingData {
  const { getUrlParams } = useContext(ConsumerContext);

  // Job ID is represented within 'id' query param. Example: job?id=R000000
  const jobId = getUrlParams?.().id;

  const { data, isLoading } = useAsyncData({
    dataId: `job-${jobId}`,
    dataAsync: async () => {
      const port = process.env.PORT ?? 3000;
      const host = Config.isClient ? '' : `http://localhost:${port}`;

      if (!jobId) {
        throw new Error('Job ID not found in query params');
      }

      const url = `${host}/api/job_details?id=${jobId}`;

      const res = await customFetch(url);

      if (!res.ok) {
        throw new Error(`Error: ${res.status} ${res.statusText}`);
      }

      const data = await res.json();
      return data;
    },
    onError: error =>
      logger.logError({ component: 'JobPosting', message: 'Error loading job posting', error }),
  });

  return {
    data: data?.body,
    isLoading,
  };
}
