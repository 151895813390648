import { cx } from '@emotion/css';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { Anchor } from '../../../../components/Anchor';
import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { UserAction } from '../../../../types/events';
import {
  persistentCallToActionBlackBgCss,
  persistentCallToActionButtonBlackCss,
  persistentCallToActionButtonGrayCss,
  persistentCallToActionButtonStylesCss,
  persistentCallToActionButtonWhiteCss,
  persistentCallToActionButtonYellowCss,
  persistentCallToActionGrayBgCss,
  persistentCallToActionWhiteBgCss,
  persistentCallToActionWrapperCss,
  persistentCallToActionYellowBgCss,
} from './PersistentCallToAction.styles';
import type { PersistentCallToActionProps } from './types';

// TODO: This component should use motifs instead of accepting a background color and button color
// https://jira.sc-corp.net/browse/WEBP-11039
export const PersistentCallToAction: FC<PersistentCallToActionProps> = ({
  analytics,
  backgroundColor,
  buttonColor,
  body,
  url,
}) => {
  const { logEvent } = useContext(ConsumerContext);

  return (
    <div
      className={cx(persistentCallToActionWrapperCss, {
        [persistentCallToActionYellowBgCss]: backgroundColor === BackgroundColor.Yellow,
        [persistentCallToActionWhiteBgCss]: backgroundColor === BackgroundColor.White,
        [persistentCallToActionGrayBgCss]: backgroundColor === BackgroundColor.Gray,
        [persistentCallToActionBlackBgCss]: backgroundColor === BackgroundColor.Black,
      })}
    >
      <Anchor
        className={cx(persistentCallToActionButtonStylesCss, {
          [persistentCallToActionButtonYellowCss]: buttonColor === BackgroundColor.Yellow,
          [persistentCallToActionButtonWhiteCss]: buttonColor === BackgroundColor.White,
          [persistentCallToActionButtonGrayCss]: buttonColor === BackgroundColor.Gray,
          [persistentCallToActionButtonBlackCss]: buttonColor === BackgroundColor.Black,
        })}
        href={url}
        onClick={() => analytics && logEvent?.({ type: UserAction.Click, label: analytics.label })}
      >
        {body}
      </Anchor>
    </div>
  );
};
