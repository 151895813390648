import { Partition } from '@snapchat/graphene';
import { Template } from '@snapchat/snap-design-system-marketing';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'careers.snap.com',
  theme: {
    breakTemplates: [
      Template.Straight,
      Template.Straight,
      Template.Straight,
      Template.Straight,
      Template.Straight,
      Template.Straight,
    ],
    heroBreakTemplate: Template.Straight,
  },

  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.CAREERS },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleAnalyticsId: '41740027-49',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'careers-snap-com',
        dsn: 'https://451b15d21cc54e9c850579f50cff436a@sentry.sc-prod.net/77',
      },
    ],
  },
  globalNavProps: {
    siteName: '',
    backgroundColor: 'White',
    defaultGroupKey: 'snap',
  },
};
