import { HeroSize } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { Hero } from '../../../../components/Hero';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { useJobPosting } from '../JobPosting/useJobPosting';
import type { JobHeroDataHandlerProps } from './JobHero.query';
import { jobHeroQuery } from './JobHero.query';

export const JobHero: FC<{ id: string }> = props => {
  const { data: blockData } = useContentfulQuery<JobHeroDataHandlerProps, ContentfulIdVariable>(
    jobHeroQuery,
    {
      variables: { id: props.id },
    }
  );

  const { data: jobData, isLoading } = useJobPosting();

  // Redirect to 404 page if Job data is not found
  if (!isLoading && !jobData?.id) {
    return <Redirect path={'/404'} />;
  }

  if (isLoading || !blockData || !jobData) return null;

  return (
    <Hero
      {...blockData?.jobHero}
      backgroundMediaV2={blockData?.jobHero.backgroundMedia}
      curtainOpacityPercentage={50}
      eyebrow={jobData?.departments}
      showMediaMobile
      size={HeroSize.Compact}
      textAlign="Center"
      textAlignMobile="Center"
      title={jobData?.title}
      verticalTextAlign="Middle"
    />
  );
};
