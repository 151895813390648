import { css } from '@emotion/css';
import { Gray, m, p2Css, Plain } from '@snapchat/snap-design-system-marketing';

import { mediaQuery } from '../../../../styles/mediaQueries';

export const inputCss = css`
  ${p2Css}
  vertical-align: middle;
  color: ${Gray.V300};
  width: 100%;
  border-style: none;
  outline: none;
  margin: ${m('--spacing-s')} 0;
  padding: 0;
  *[dir='rtl'] & {
    text-align: right;
  }
`;

export const styledIconCss = css`
  color: ${Gray.V300};
  margin: ${m('--spacing-m')};
`;

export const rowCss = css`
  margin-top: ${m('--spacing-s')};
  margin-bottom: ${m('--spacing-s')};
  display: flex;
  background-color: ${Plain.WHITE};
  align-items: center;
  border-radius: ${m('--border-radius-l')};
  min-width: 300px;

  ${mediaQuery.over1024_desktop_medium} {
    margin: 0;
  }
`;
