import {
  CalendarIcon,
  ClockIcon,
  DocumentIcon,
  GlobeIcon,
} from '@snapchat/snap-design-system-icons';
import { FormattedMessage } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import {
  iconContainerCss,
  iconCss,
  jobListingHeaderContainerCss,
  officesContainerCss,
  textIconCss,
} from './JobListingHeader.styles';
import type { JobListingHeaderProps } from './types';

const isValidDate = (dateStr: string) => {
  return !Number.isNaN(new Date(dateStr).getDate());
};

/** Get the number of days ago from a start date. Assumes start date is given UTC timezone. */
const getDaysAgoFromStartDate = (startDateString: string) => {
  const daysAgo = Math.floor(
    (Date.now() - new Date(`${startDateString}T00:00:00Z`).getTime()) / (1000 * 60 * 60 * 24)
  );

  return Math.max(0, daysAgo).toString();
};

export const JobListingHeader: FC<JobListingHeaderProps> = props => {
  const { jobId, offices, startDate, timeType } = props;

  return (
    <article className={jobListingHeaderContainerCss}>
      {offices?.length && (
        <section className={iconContainerCss}>
          {/* Offices */}
          <div className={iconCss}>
            <GlobeIcon />
          </div>
          <div className={officesContainerCss}>
            {offices?.map((office, index) => (
              <p key={index} className={textIconCss}>
                {office.name}
              </p>
            ))}
          </div>
        </section>
      )}
      {timeType && (
        <section className={iconContainerCss}>
          {/* Time type */}
          <div className={iconCss}>
            <ClockIcon />
          </div>
          <p className={textIconCss}>{timeType}</p>
        </section>
      )}
      {isValidDate(startDate) && (
        <section className={iconContainerCss}>
          {/* Start date */}
          <div className={iconCss}>
            <CalendarIcon />
          </div>
          <p className={textIconCss}>
            <FormattedMessage
              id="careersPostingDatePhrase"
              values={{ daysAgo: getDaysAgoFromStartDate(startDate) }}
              defaultMessage="Posted {daysAgo} days ago"
            />
          </p>
        </section>
      )}
      {jobId && (
        <section className={iconContainerCss}>
          {/* Job ID */}
          <div className={iconCss}>
            <DocumentIcon />
          </div>
          <p className={textIconCss}>{jobId}</p>
        </section>
      )}
    </article>
  );
};
